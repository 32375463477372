.fg {
  display: flex;
}

.ftitle {
  background-color: #f7f7f7;
  color: #333;
  border-radius: 6px;
  padding: 2px 2px 2px 8px;
  margin: 0px 0px 14px 0px;
  font-size: 12pt;
  font-weight: bold;
}

.editor-size-select {
  width: 100px;
}

.toolbar {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.buttons-vertical {
  padding: 2px;
  display:flex; 
  align-items: center;
  gap: 2px;
}

.buttons-vertical-top {
  padding: 2px;
  display:flex; 
  align-items: flex-start;
  gap: 2px;
}

.buttons-horizontal {
  padding: 2px;
  display:flex; 
  align-items: flex-start;
  gap: 2px;
}

.buttons-inline {
  padding: 2px;
  display:flex; 
  align-items: flex-start;
  gap: 2px;
}

.mr-8 {
  margin-right: 8px;
}

.fi {
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 2px;
}

.fi-xsmall { 
  flex:1;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fi-small { 
  flex:2;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fi-normal { 
  flex:3;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fi-large { 
  flex:4;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fi-xlarge { 
  flex:5;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fi-hide {
  border: 2px solid orange;
}

.fi-full { 
  flex:6;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fi-full-textarea { 
  flex:6;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fi-full-textarea textarea { 
   min-height: 300px;
   background-color: #333;
   color: lightgreen;
   font-family: 'Courier New', Courier, monospace;
   font-size: small;
}

.fi-header * {
  border: 0px;
  font-weight: bold;
}

.form-section-header {
  background-color: #eee;
  padding: 1px 8px;
  margin: 2px 15px 10px 0px;
  border-radius: 4px;
  color: #444;
  font-weight: bold;
  font-size: 10pt;
}


.icon-24 {
  font-size: 24px;
}

.debug {
  border: 1px solid blue;
  background-color: rgb(0, 115, 255);
}
