@import '../abstracts/mixins.scss';

.interface-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.interface-section-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  grid-auto-rows: min-content;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  .interface-field {
    grid-column: span 1;
    // &.xs {
    //   width: 50%;
    // }
    @include breakpoint(lg, min) {
      &.xl {
        grid-column: span 2;
      }
      &.xxl {
        grid-column: span 3;
      }
    }
    &.max {
      grid-column: 1/-1;
    }
  }
}

// .interface-section-container {
//   display: flex;
//   flex-wrap: wrap;
//   gap: 0.5rem;
//   width: 100%;
//   height: 100%;
//   .interface-field {
//     flex: 1;
//     width: 100%;
//     @include breakpoint(md, min) {
//       min-width: 200px;
//       &.xl {
//         flex: 2;
//         min-width: 250px;
//       }
//       &.xxl {
//         flex: 3;
//         min-width: 300px;
//       }
//     }
//   }
// }

.interface-section-label {
  @apply text-xs font-bold text-gray-800 bg-gray-200 rounded;
  padding: 2px 4px;
}

.interface-section-label-error {
  @apply text-xs font-bold text-red-800 bg-red-200 rounded;
  padding: 2px 4px;
}
